import React, { useState } from "react";
import { ConnectOptions } from "twilio-video";
import Room from "./components/Room";

import "./styles.css";

import { InputOverrides, Perspective } from "../types";
import { AudioOutputContext } from "../hooks";

const defaultRemoteParticipantVolume = 1.0;
const defaultRemotePresenterVolume = 1.0;

type Props = {
  accessToken: string;
  connectOptions?: ConnectOptions;
  /** @deprecated */
  inputSettings?: InputOverrides;
  /** @deprecated */
  isPopped?: boolean;
  noCmp?: boolean;
  remoteParticipantVolume?: number;
  remotePresenterVolume?: number;
  roomName: string;
  perspective?: Perspective;
  isV2Cmp?: boolean;
};

const App: React.FC<Props> = (props) => {
  const {
    accessToken,
    connectOptions,
    noCmp = false,
    remoteParticipantVolume = defaultRemoteParticipantVolume,
    remotePresenterVolume = defaultRemotePresenterVolume,
    roomName,
    perspective = Perspective.presenter,
    isV2Cmp = false,
  } = props;

  const [audioOutputMuted, setAudioOutputMuted] = useState<boolean>(false);
  const [audioOutputDeviceId, setAudioOutputDeviceId] = useState<string | null>(
    null
  );

  return (
    <AudioOutputContext.Provider
      value={{
        deviceId: audioOutputDeviceId,
        muted: audioOutputMuted,
        setDeviceId: (deviceId) => setAudioOutputDeviceId(deviceId),
        setMuted: (muted) => setAudioOutputMuted(muted),
      }}
    >
      {accessToken && roomName && (
        <Room
          accessToken={accessToken}
          connectOptions={connectOptions}
          noCmp={noCmp}
          perspective={perspective}
          remoteParticipantVolume={remoteParticipantVolume}
          remotePresenterVolume={remotePresenterVolume}
          roomName={roomName}
          isV2Cmp={isV2Cmp}
        />
      )}
    </AudioOutputContext.Provider>
  );
};

export default App;
